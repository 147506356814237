import { ChangeEvent, useEffect, useState } from "react";
import Appointment from "../model/Appointment";
import Notice from "./Notice";
import SubTitle from "./SubTitle";
import "react-calendar/dist/Calendar.css";
import PickCalendarComponent from "./PickCalendarDateComponent";
import { useForm } from "react-hook-form";
import ArrivalDatePicker from "./admin/ArrivalDatePicker";

const getTotalPrice = async (
  appointment: Appointment
): Promise<string | undefined> => {
  const response = await fetch("/api/form/totalPrice", {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(appointment),
  });

  if (response.status !== 200) {
    return Promise.reject("Cannot determine price " + response.body);
  }

  return response.text();
};

export default function AppointmentDetails({
  appointment,
  onChange,
}: {
  onChange: (appointment: Appointment) => void;
  appointment: Appointment;
}) {
  const registrationNotAllowed =
    appointment.placeOfResidence === "Different" ||
    (appointment.placeOfResidence === "NoAddress" &&
      (appointment.companyPlace === "Different" ||
        appointment.companyPlace === ""));

  const [totalPrice, setTotalPrice] = useState<number | undefined>();

  useEffect(() => {
    getTotalPrice(appointment).then((value: string | undefined) => {
      if (value === undefined || value === "") {
        setTotalPrice(undefined);
      } else {
        setTotalPrice(Number.parseFloat(value));
      }
    });
  }, [appointment.nrOfAdults, appointment.nrOfChildren, appointment]);

  const { register, watch, formState } = useForm<Appointment>({
    mode: "all",
    defaultValues: appointment,
  });

  const { placeOfResidence, companyPlace, nrOfAdults, nrOfChildren } = watch();

  return (
    <>
      <SubTitle>Appointment</SubTitle>
      <label htmlFor="placeOfResidence" className="form-label">
        What is your place of residence? / Do you have a permanent address?
      </label>

      <select
        id="placeOfResidence"
        className={`form-select ${
          formState.touchedFields.placeOfResidence || placeOfResidence
            ? formState.errors.placeOfResidence !== undefined
              ? "is-invalid"
              : "is-valid"
            : ""
        }`}
        {...register("placeOfResidence", {
          required: true,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            onChange({
              ...appointment,
              placeOfResidence: e.target.value,
            });
          },
        })}
        value={appointment.placeOfResidence || ""}
        aria-label="What is your place of residence? / Do you have a permanent address?"
      >
        <option value="">Select place</option>
        <option value="Leiden">Leiden</option>
        <option value="Leiderdorp">Leiderdorp</option>
        <option value="Voorschoten">Voorschoten</option>
        <option value="Zoeterwoude">Zoeterwoude</option>
        <option value="Different">
          I have a permant address in a different town
        </option>
        <option value="NoAddress">I do not have a permanent address yet</option>
      </select>

      {appointment.placeOfResidence === "NoAddress" ? (
        <>
          <label htmlFor="companyPlace" className="form-label">
            Where is your company located?
          </label>

          <select
            id="companyPlace"
            className={`form-select ${
              formState.touchedFields.companyPlace || companyPlace
                ? formState.errors.companyPlace !== undefined
                  ? "is-invalid"
                  : "is-valid"
                : ""
            }`}
            {...register("companyPlace", {
              required: true,
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                onChange({
                  ...appointment,
                  companyPlace: e.target.value,
                });
              },
            })}
            aria-label="What is your place of residence? / Do you have a permanent address?"
          >
            <option value="">Select place</option>
            <option value="Leiden">Leiden</option>
            <option value="Leiderdorp">Leiderdorp</option>
            <option value="Voorschoten">Voorschoten</option>
            <option value="Zoeterwoude">Zoeterwoude</option>
            <option value="Different">
              The company I work for is located in a different town
            </option>
          </select>
        </>
      ) : undefined}

      {registrationNotAllowed ? (
        <Notice>
          You cannot register at the Leiden International Centre. Please contact
          the{" "}
          <a href="https://www.leideninternationalcentre.nl/our-story">
            town hall
          </a>{" "}
          of the relevant municipality.
        </Notice>
      ) : undefined}

      {!registrationNotAllowed ? (
        <>
          <div className="">
            <label htmlFor="nrOfAdults" className="form-label">
              Total number of adults you wish to register (including yourself)
            </label>
            <input
              type="number"
              id="nrOfAdults"
              className={`form-control ${
                formState.touchedFields.nrOfAdults || nrOfAdults
                  ? formState.errors.nrOfAdults !== undefined
                    ? "is-invalid"
                    : "is-valid"
                  : ""
              }`}
              min="1"
              max="5"
              {...register("nrOfAdults", {
                required: true,
                min: 1,
                max: 5,
                onChange: (e: ChangeEvent<HTMLInputElement>) => {
                  onChange({
                    ...appointment,
                    nrOfAdults: Number.parseInt(e.target.value),
                  });
                },
              })}
              placeholder="Total number of adults"
            />
            <div id="validationNrOfAdults" className="invalid-feedback">
              At least 1 and no more then 5 adults are allowed.
            </div>
          </div>
          <div className="">
            <label htmlFor="nrOfChildren" className="form-label">
              Total number of children you wish to register
            </label>
            <input
              type="number"
              min="0"
              max="10"
              className={`form-control ${
                formState.touchedFields.nrOfChildren || nrOfChildren
                  ? formState.errors.nrOfChildren !== undefined
                    ? "is-invalid"
                    : "is-valid"
                  : ""
              }`}
              id="nrOfChildren"
              {...register("nrOfChildren", {
                required: true,
                min: 0,
                max: 10,
                onChange: (e: ChangeEvent<HTMLInputElement>) => {
                  onChange({
                    ...appointment,
                    nrOfChildren: Number.parseInt(e.target.value),
                  });
                },
              })}
              placeholder="Total number of children"
            />
            <div id="validationNrOfChildren" className="invalid-feedback">
              No more then 10 children are allowed.
            </div>
          </div>
          <div className="">
            <label className="form-label">
              Does everyone attending your appointment have an EU nationality?
            </label>
            <div className="form-check">
              <input
                value="true"
                type="radio"
                id="allEUCitizens_yes"
                className="form-check-input"
                checked={appointment?.allEUCitizens === true}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  onChange({
                    ...appointment,
                    allEUCitizens: true,
                  });
                }}
              />
              <label
                className="radiolabel form-check-label"
                htmlFor="allEUCitizens_yes"
              >
                Yes, everyone attending the appointment has an EU nationality
              </label>
            </div>
            <div className="form-check">
              <input
                type="radio"
                value="false"
                id="allEUCitizens_no"
                className="form-check-input"
                checked={appointment?.allEUCitizens === false}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  onChange({
                    ...appointment,
                    allEUCitizens: false,
                  });
                }}
              />
              <label
                className="radiolabel form-check-label"
                htmlFor="allEUCitizens_no"
              >
                No, 1 or more people attending the appointment have a
                nationality from outside the EU
              </label>
            </div>
          </div>
          <div className="mt-3">
            <ul>
              <li>Service fee first family member: € 55.00</li>
              <li>Service fee additional adults: € 30.00</li>
              <li>Service fee child: € 15.00</li>
            </ul>
          </div>
          {totalPrice !== undefined ? (
            <div>Total price excluding VAT €{totalPrice.toFixed(2)}</div>
          ) : undefined}
          <div className="">
            <label htmlFor="arrivalDay" className="form-label">
              When will you arrive in the Netherlands?
            </label>
          </div>
          <ArrivalDatePicker
            value={appointment.arrivalDay}
            onChange={(newDay: Date | undefined) => {
              onChange({
                ...appointment,
                arrivalDay: newDay,
              });
            }}
          />
          {appointment.arrivalDay !== undefined &&
          appointment.allEUCitizens !== undefined ? (
            <div className="">
              <label htmlFor="appointmentDate" className="form-label">
                Choose a date for the registration/appointment
              </label>
              <PickCalendarComponent
                nrOfAdults={appointment.nrOfAdults || 0}
                nrOfChildren={appointment.nrOfChildren || 0}
                allEUCitizens={
                  appointment.allEUCitizens !== undefined
                    ? appointment.allEUCitizens
                    : true
                }
                arrivalDay={appointment.arrivalDay}
                appointmentDate={appointment.appointmentDate}
                onChange={(newDay, newDurationInMinutes) => {
                  onChange({
                    ...appointment,
                    appointmentDate: newDay,
                    durationInMinutes: newDurationInMinutes,
                  });
                }}
              />
            </div>
          ) : undefined}
          {appointment.appointmentDate !== undefined ? (
            <>
              <div className="form-check mt-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="signMeUpForExpatNewsletter"
                  checked={appointment.signMeUpForExpatNewsletter === true}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    if (event.target.checked) {
                      appointment.signMeUpForExpatNewsletter = true;
                    } else {
                      appointment.signMeUpForExpatNewsletter = false;
                    }

                    onChange(Object.assign({}, appointment));
                  }}
                />
                <label
                  htmlFor="signMeUpForExpatNewsletter"
                  className="radiolabel form-label"
                >
                  Sign me up for the Leiden International Centre Newsletter
                </label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="signMeUpForCorporateNewsletter"
                  checked={appointment.signMeUpForCorporateNewsletter === true}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    if (event.target.checked) {
                      appointment.signMeUpForCorporateNewsletter = true;
                    } else {
                      appointment.signMeUpForCorporateNewsletter = false;
                    }

                    onChange(Object.assign({}, appointment));
                  }}
                />
                <label
                  htmlFor="signMeUpForCorporateNewsletter"
                  className="radiolabel form-label"
                >
                  Sign me up for the Corporate Newsletter
                </label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="iWantToReceiveInvitationsForExpatCentreLeidenEvents"
                  checked={
                    appointment.iWantToReceiveInvitationsForExpatCentreLeidenEvents ===
                    true
                  }
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    if (event.target.checked) {
                      appointment.iWantToReceiveInvitationsForExpatCentreLeidenEvents =
                        true;
                    } else {
                      appointment.iWantToReceiveInvitationsForExpatCentreLeidenEvents =
                        false;
                    }

                    onChange(Object.assign({}, appointment));
                  }}
                />
                <label
                  htmlFor="iWantToReceiveInvitationsForExpatCentreLeidenEvents"
                  className="radiolabel form-label"
                >
                  I want to receive invitations for Leiden International Centre
                  events.
                </label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="agreeToTerms"
                  checked={appointment.agreeToTerms === true}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    if (event.target.checked) {
                      appointment.agreeToTerms = true;
                    } else {
                      appointment.agreeToTerms = false;
                    }

                    onChange(Object.assign({}, appointment));
                  }}
                />
                <label htmlFor="agreeToTerms" className="radiolabel form-label">
                  I agree to the terms and conditions
                </label>
              </div>
            </>
          ) : undefined}
        </>
      ) : undefined}
    </>
  );
}
