import { useEffect, useState } from "react";

interface EnvironmentSettings {
  eclBaseUrl: string;
  databaseUrl: string;
  mollieApiKey: string;
  teamleaderClientId: string;
  teamleaderSecret: string;
  sendGridKey: string;
  oauthAuthorizeUrl: string;
  appointmentCardEmail: string;
  teamLeaderActive: boolean;
}

const getEnvironment = async (): Promise<EnvironmentSettings> => {
  return fetch("/api/admin/env").then((result) => {
    return result.json();
  });
};

export default function Env() {
  const [currentEnvironment, setEnvironment] = useState<
    EnvironmentSettings | undefined
  >(undefined);

  useEffect(() => {
    getEnvironment().then((env: EnvironmentSettings) => {
      setEnvironment(env);
    });
  }, []);

  if (currentEnvironment === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <form className="Settings">
      <h1>Environment</h1>
      <table className="mt-3 table">
        <tbody>
          <tr>
            <td>DATABASE_URL</td>
            <td>{currentEnvironment.databaseUrl}</td>
          </tr>
          <tr>
            <td>ECL_BASE_URL</td>
            <td>{currentEnvironment.eclBaseUrl}</td>
          </tr>
          <tr>
            <td>MOLLIE_API_KEY</td>
            <td>{currentEnvironment.mollieApiKey}</td>
          </tr>
          <tr>
            <td>TEAMLEADER_CLIENT_ID</td>
            <td>{currentEnvironment.teamleaderClientId}</td>
          </tr>
          <tr>
            <td>TEAMLEADER_CLIENT_SECRET</td>
            <td>{currentEnvironment.teamleaderSecret}</td>
          </tr>
          <tr>
            <td>SENDGRID_KEY</td>
            <td>{currentEnvironment.sendGridKey}</td>
          </tr>
          <tr>
            <td>APPOINTMENT_CARD_EMAIL</td>
            <td>{currentEnvironment.appointmentCardEmail}</td>
          </tr>
          <tr>
            <td>TEAMLEADER ACCESS TOKEN VALID</td>
            <td>
              {currentEnvironment.teamLeaderActive ? (
                <div>
                  <i
                    style={{ color: "green" }}
                    className="fa-solid fa-check"
                  ></i>
                </div>
              ) : (
                <a
                  className="btn btn-primary"
                  href={currentEnvironment.oauthAuthorizeUrl}
                >
                  SETUP
                </a>
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <div></div>
    </form>
  );
}
