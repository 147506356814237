import moment from "moment";
import { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export default function Exports() {
  const [startDate, setStartDate] = useState<Date>(
    moment().subtract(3, "months").startOf("month").toDate()
  );
  const [endDate, setEndDate] = useState<Date>(moment().toDate());

  return (
    <>
      <div className="row">
        <h2>Export to excel</h2>
      </div>
      <div className="row">
        <div className="mb-3 col-3">
          <label htmlFor="startDate" className="form-label">
            All appointsments AFTER
          </label>
          <DatePicker
            className="form-control"
            name="startDate"
            selected={startDate}
            onChange={(date: Date) => setStartDate(date)}
          />
        </div>
        <div className="mb-3 col-3">
          <label htmlFor="endDate" className="form-label">
            All appointsments BEFORE
          </label>
          <DatePicker
            className="form-control"
            selected={endDate}
            name="endDate"
            onChange={(date: Date) => setEndDate(date)}
          />
        </div>
      </div>
      <div className="row">
        <div className="mb-3 col-3">
          <button
            className="btn btn-secondary mt-4"
            onClick={(event) => {
              event.preventDefault();

              window.open(
                "/api/admin/exportexcel?startDate=" +
                  moment(startDate).format("YYYY-MM-DD") +
                  "&endDate=" +
                  moment(endDate).format("YYYY-MM-DD"),
                "_blank"
              );
            }}
          >
            EXPORT
          </button>
        </div>
      </div>
    </>
  );
}
