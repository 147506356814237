import { LengthOfStayEnum } from "../model/Appointment";

export default function LengthOfStay({
  lengthOfStay,
  onChange,
}: {
  onChange: (lengthOfStay: LengthOfStayEnum) => void;
  lengthOfStay: LengthOfStayEnum | undefined;
}) {
  return (
    <>
      <label htmlFor="lengthOfStay" className="form-label">
        Length of stay in the Netherlands
      </label>

      <select
        id="lengthOfStay"
        className="form-select"
        value={lengthOfStay}
        aria-label="Length of stay in the Netherlands"
        onChange={(e) => {
          if (isNaN(parseInt(e.target.value))) {
            return;
          }

          onChange(parseInt(e.target.value));
        }}
      >
        <option value="">Select length of stay</option>
        <option value={LengthOfStayEnum.LESS_THEN_4MONTH}>
          Less than 4 months
        </option>
        <option value={LengthOfStayEnum.LONGER_THEN_4MONTH}>
          Longer than 4 months
        </option>
      </select>
    </>
  );
}
