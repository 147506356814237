import { ToastProvider } from "react-toast-notifications";
import { Container } from "react-bootstrap";
import Header from "../components/admin/header/Header";
import { useEffect, useState } from "react";
import UserInfo from "../model/UserInfo";
import { me } from "../services/APIService";
import { Navigate, Route, Routes } from "react-router-dom";
import Settings from "../components/admin/Settings";
import "../components/admin/Admin.css";
import ListAppointments from "../components/admin/appointments/ListAppointments";
import Exports from "../components/admin/exports/Exports";
import ListIntermdiaries from "../components/admin/intermediaries/ListIntermdiaries";
import EditIntermediary from "../components/admin/intermediaries/EditIntermediary";
import Admin from "../components/admin/Env";
import Env from "../components/admin/Env";

export default function AdminPage() {
  const [userInfo, setUserInfo] = useState<UserInfo | undefined | null>(
    undefined
  );

  useEffect(() => {
    me().then((info: UserInfo | undefined) => {
      if (info === undefined) {
        setUserInfo(null);
      } else {
        setUserInfo(info);
      }
    });
  }, []);
  if (userInfo === undefined) {
    return <div>Loading....</div>;
  }
  if (userInfo === null) {
    return <Navigate to="/admin/login" />;
  }

  return (
    <ToastProvider>
      <Container fluid={true}>
        <Header userInfo={userInfo}></Header>

        <Routes>
          <Route path="/env" element={<Env />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/exports" element={<Exports />} />
          <Route path="/intermediaries" element={<ListIntermdiaries />} />
          <Route path="/intermediaries/:id" element={<EditIntermediary />} />
          <Route path="/appointments" element={<ListAppointments />} />
          <Route path="*" element={<div>Welcome to the admin module</div>} />
        </Routes>
      </Container>
    </ToastProvider>
  );
}
