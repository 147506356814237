import { ChangeEvent, useEffect } from "react";
import Attendee, { GenderEnum } from "../model/Attendee";
import { useForm } from "react-hook-form";

const validateEmail = (email: string): boolean => {
  return (
    email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ) !== null
  );
};

export default function AttendeeInformation({
  attendee,
  onChange,
}: {
  onChange: (attendee: Attendee) => void;
  attendee: Attendee | undefined;
}) {
  const { register, watch, formState } = useForm<Attendee>({
    mode: "all",
    defaultValues: attendee,
  });

  const { isValid } = formState;
  const { firstname, lastName, companyName, nationality, email, phone } =
    watch();

  useEffect(() => {
    if (attendee?.isValid !== isValid) {
      onChange({ ...attendee, isValid: isValid });
    }
  }, [attendee, isValid, onChange]);

  return (
    <>
      <form>
        <div className="form-group has-validation">
          <label htmlFor="firstname" className="form-label">
            First name(s) (as shown in passport)
          </label>
          <input
            className={`form-control ${
              formState.touchedFields.firstname || firstname
                ? formState.errors.firstname !== undefined
                  ? "is-invalid"
                  : "is-valid"
                : ""
            }`}
            {...register("firstname", {
              required: true,
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                onChange({
                  ...attendee,
                  firstname: e.target.value,
                });
              },
            })}
          />
          <div id="validationFirstname" className="invalid-feedback">
            Please provide a valid firstname
          </div>
        </div>
        <div className="form-group has-validation">
          <label htmlFor="lastName" className="form-label">
            Last name(s) (as shown in passport)
          </label>
          <input
            type="text"
            className={`form-control ${
              formState.touchedFields.lastName || lastName
                ? formState.errors.lastName !== undefined
                  ? "is-invalid"
                  : "is-valid"
                : ""
            }`}
            {...register("lastName", {
              required: true,
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                onChange({
                  ...attendee,
                  lastName: e.target.value,
                });
              },
            })}
          />
          <div id="validationLastname" className="invalid-feedback">
            Please provide a valid lastname
          </div>
        </div>
        <div className="">
          <label className="form-label">Gender</label>
          <div className="form-check">
            <input
              value="female"
              type="radio"
              id="gender_female"
              className="form-check-input"
              checked={attendee?.gender === GenderEnum.female}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChange({
                  ...attendee,
                  gender: GenderEnum.female,
                });
              }}
            />
            <label
              className="radiolabel form-check-label"
              htmlFor="gender_female"
            >
              female
            </label>
          </div>
          <div className="form-check">
            <input
              type="radio"
              value="male"
              id="gender_male"
              className="form-check-input"
              checked={attendee?.gender === GenderEnum.male}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChange({
                  ...attendee,
                  gender: GenderEnum.male,
                });
              }}
            />
            <label
              className="radiolabel form-check-label"
              htmlFor="gender_male"
            >
              male
            </label>
          </div>
          <div className="form-check">
            <input
              type="radio"
              value="unknown"
              id="gender_unknown"
              checked={attendee?.gender === GenderEnum.unkown}
              className="form-check-input"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChange({
                  ...attendee,
                  gender: GenderEnum.unkown,
                });
              }}
            />
            <label
              className="radiolabel form-check-label"
              htmlFor="gender_unknown"
            >
              other / I prefer not to respond
            </label>
          </div>
        </div>
        <div className="form-group has-validation">
          <label htmlFor="companyName" className="form-label">
            Employer / Company name
          </label>
          <input
            className={`form-control ${
              formState.touchedFields.companyName || companyName
                ? formState.errors.companyName !== undefined
                  ? "is-invalid"
                  : "is-valid"
                : ""
            }`}
            {...register("companyName", {
              required: true,
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                onChange({
                  ...attendee,
                  companyName: e.target.value,
                });
              },
            })}
          />
          <div id="validationCompanynamer" className="invalid-feedback">
            Please provide a valid company name
          </div>
        </div>
        <div className="form-group has-validation">
          <label htmlFor="nationality" className="form-label">
            Nationality
          </label>
          <input
            className={`form-control ${
              formState.touchedFields.nationality || nationality
                ? formState.errors.nationality !== undefined
                  ? "is-invalid"
                  : "is-valid"
                : ""
            }`}
            {...register("nationality", {
              required: true,
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                onChange({
                  ...attendee,
                  nationality: e.target.value,
                });
              },
            })}
          />
          <div id="validationnationality" className="invalid-feedback">
            Please provide a valid nationality
          </div>
        </div>
        <div className="form-group has-validation">
          <label htmlFor="email" className="form-label">
            Email address
          </label>
          <input
            className={`form-control ${
              formState.dirtyFields.email || email
                ? formState.errors.email !== undefined
                  ? "is-invalid"
                  : "is-valid"
                : ""
            }`}
            type="email"
            {...register("email", {
              required: true,
              validate: (value: string | undefined) => {
                return validateEmail(value || "");
              },
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                onChange({
                  ...attendee,
                  email: e.target.value,
                });
              },
            })}
          />
          <div id="validationnationality" className="invalid-feedback">
            Please provide a valid email address
          </div>
        </div>
        <div className="form-group has-validation">
          <label htmlFor="phone" className="form-label">
            Phone number
          </label>
          <input
            type="tel"
            {...register("phone", {
              required: true,
              minLength: 10,
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                onChange({
                  ...attendee,
                  phone: e.target.value,
                });
              },
            })}
            className={`form-control ${
              formState.dirtyFields.phone || phone
                ? formState.errors.phone
                  ? "is-invalid"
                  : "is-valid"
                : ""
            }`}
            placeholder="Phone number"
          />
          <div className="invalid-feedback">Please provide a phone number</div>
        </div>
      </form>
    </>
  );
}
