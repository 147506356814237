import moment, { MomentInput } from "moment-timezone";
import { ChangeEvent, useEffect, useState } from "react";
import Calendar, {
  CalendarTileProperties,
  ViewCallbackProperties,
} from "react-calendar";
import _ from "lodash";
import {
  AvailabilityRequest,
  AvailabilityResponse,
} from "../model/AvailabilityRequest";

const getAvailabilty = async (
  month: Date,
  nrOfAdults: number | undefined,
  nrOfChildren: number | undefined,
  allEUCitizens: boolean
): Promise<AvailabilityResponse[]> => {
  var beginingOfMonth: string =
    month !== undefined
      ? moment
          .tz(month, "Europe/Amsterdam")
          .startOf("month")
          .format("YYYY-MM-DD")
      : moment().startOf("month").format("YYYY-MM-DD");

  var endingOfMonth: string =
    month !== undefined
      ? moment.tz(month, "Europe/Amsterdam").endOf("month").format("YYYY-MM-DD")
      : moment().endOf("month").format("YYYY-MM-DD");

  const request: AvailabilityRequest = {
    from: beginingOfMonth,
    until: endingOfMonth,
    nrOfAdults: nrOfAdults,
    nrOfChildren: nrOfChildren,
    allEUCitizens: allEUCitizens,
  };

  const response = await fetch("/api/form/bookableDates", {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  });

  return response.json();
};

export default function PickCalendarComponent({
  arrivalDay,
  appointmentDate,
  nrOfAdults,
  nrOfChildren,
  allEUCitizens,
  onChange,
}: {
  arrivalDay: Date;
  appointmentDate: Date | undefined;
  nrOfAdults: number;
  nrOfChildren: number;
  allEUCitizens: boolean;
  onChange: (
    arrivalDay: Date | undefined,
    timeInDuration: number | undefined
  ) => void;
}) {
  const [filterMonth, setFilterMonth] = useState<Date | undefined>(
    arrivalDay !== undefined && moment().isBefore(arrivalDay)
      ? moment
          .tz(moment(arrivalDay).format("YYYY-MM-DD"), "Europe/Amsterdam")
          .startOf("month")
          .toDate()
      : moment().tz("Europe/Amsterdam").toDate()
  );
  const [availabilities, setAvailabilities] = useState<
    AvailabilityResponse[] | undefined
  >(undefined);

  useEffect(() => {
    if (appointmentDate === undefined) {
      if (arrivalDay !== undefined && moment().isBefore(arrivalDay)) {
        setFilterMonth(
          moment
            .tz(moment(arrivalDay).format("YYYY-MM-DD"), "Europe/Amsterdam")
            .startOf("month")
            .toDate()
        );
      } else {
        setFilterMonth(
          moment
            .tz(moment().format("YYYY-MM-DD"), "Europe/Amsterdam")
            .startOf("month")
            .toDate()
        );
      }
    } else {
      setFilterMonth(appointmentDate);
    }
  }, [arrivalDay, appointmentDate]);

  useEffect(() => {
    async function updateData() {
      var response: AvailabilityResponse[] = await getAvailabilty(
        filterMonth || moment().toDate(),
        nrOfAdults,
        nrOfChildren,
        allEUCitizens
      );

      setAvailabilities(
        _.map(response, (item: AvailabilityResponse) => {
          return {
            date: moment
              .tz(
                moment.tz(item.date, "Europe/Amsterdam").format("YYYY-MM-DD") +
                  " " +
                  item.from,
                "YYYY-MM-DD HH:mm",
                "Europe/Amsterdam"
              )
              .format(),
            from: item.from,
            durationInMinutes: item.durationInMinutes,
          };
        })
      );
    }

    updateData();
  }, [filterMonth, nrOfAdults, nrOfChildren, allEUCitizens]);

  return (
    <>
      <Calendar
        minDate={
          moment(arrivalDay).isAfter(new Date())
            ? arrivalDay
            : moment().toDate()
        }
        maxDate={moment(arrivalDay).add(1, "years").toDate()}
        activeStartDate={filterMonth}
        value={appointmentDate}
        onActiveStartDateChange={(props: ViewCallbackProperties) => {
          if (props.view !== "month") {
            setFilterMonth(undefined);
            return;
          }

          if (
            !moment(props.activeStartDate).isSame(moment(filterMonth), "month")
          ) {
            let newMonth = moment
              .tz(
                moment(props.activeStartDate).format("YYYY-MM-DD"),
                "Europe/Amsterdam"
              )
              .startOf("month")
              .toDate();
            setFilterMonth(newMonth);
          }
        }}
        tileDisabled={(props: CalendarTileProperties) => {
          if (props.view !== "month") return false;
          const dCal: MomentInput = moment(props.date);

          return !_.some(availabilities, (dAvail: AvailabilityResponse) => {
            return dCal.isSame(dAvail.date, "date");
          });
        }}
        onChange={(value: Date, event: ChangeEvent) => {
          var selectedTimeSlot: AvailabilityResponse | undefined = _.find(
            availabilities,
            (dAvail: AvailabilityResponse) => {
              return moment(value).isSame(dAvail.date, "date");
            }
          );

          if (selectedTimeSlot === undefined) {
            onChange(undefined, undefined);
            return;
          }
          onChange(
            moment.tz(selectedTimeSlot.date, "Europe/Amsterdam").toDate(),
            selectedTimeSlot.durationInMinutes
          );
        }}
      />
      {appointmentDate !== undefined && availabilities !== undefined ? (
        <div className="react-calendar react-calendar__tile">
          {moment.tz(appointmentDate, "Europe/Amsterdam").format("HH : mm")} -{" "}
          {moment
            .tz(appointmentDate, "Europe/Amsterdam")
            .add(availabilities[0].durationInMinutes, "minutes")
            .format("HH : mm")}
        </div>
      ) : undefined}
    </>
  );
}
