import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import PickCalendarComponent from "../components/PickCalendarDateComponent";
import Appointment from "../model/Appointment";
import "./ReschedulePage.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const updateAppointment = async (
  bookingid: string,
  signature: string,
  expires: string,
  appointment: Appointment
): Promise<string | undefined> => {
  const response = await fetch(
    "/api/form/reschedule/" +
      encodeURIComponent(bookingid) +
      "?signature=" +
      encodeURIComponent(signature) +
      "&expires=" +
      encodeURIComponent(expires),
    {
      method: "PUT",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(appointment),
    }
  );

  if (response.status !== 200) {
    return Promise.reject(await response.text());
  }
  return response.text();
};

function ReschedulePage() {
  const { bookingid } = useParams();
  const signature = useQuery().get("signature") || "";
  const expires = useQuery().get("expires") || "";
  const [appointment, setAppointment] = useState<Appointment | undefined>(
    undefined
  );
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [ioError, setIOError] = useState<boolean>(false);

  useEffect(() => {
    fetch(
      "/api/form/reschedule/" +
        bookingid +
        "?signature=" +
        encodeURIComponent(signature) +
        "&expires=" +
        encodeURIComponent(expires)
    )
      .then(async (result) => {
        if (result.status !== 200) {
          return Promise.reject();
        }
        setAppointment(await result.json());
      })
      .catch((err) => {
        setIOError(true);
      });
  }, [bookingid, signature, expires]);

  if (ioError) {
    return (
      <div>
        There was an eror loading your appointment, if this problem persists
        please contact us by phone (+31 71 516 6005) or by email
        info@leideninternationalcentre.nl.
      </div>
    );
  }
  if (appointment === undefined || bookingid === undefined) {
    return <div>Loading...</div>;
  }

  appointment.appointmentDate = moment(appointment.appointmentDate).toDate();
  appointment.arrivalDay = moment(appointment.arrivalDay).toDate();

  return (
    <>
      <div className="container ReschedulePage">
        <div className="row">
          <h1>Reschedule appointment form</h1>
        </div>
        <div className="">
          <label htmlFor="arrivalDay" className="form-label">
            Pick a new appointment date and time
          </label>
        </div>
        <PickCalendarComponent
          nrOfAdults={appointment.nrOfAdults || 0}
          nrOfChildren={appointment.nrOfChildren || 0}
          arrivalDay={appointment.arrivalDay}
          appointmentDate={appointment.appointmentDate}
          allEUCitizens={
            appointment.allEUCitizens !== undefined
              ? appointment.allEUCitizens
              : true
          }
          onChange={(newDay, newDurationInMinutes) => {
            setAppointment({
              ...appointment,
              appointmentDate: newDay,
              durationInMinutes: newDurationInMinutes,
            });
            setButtonDisabled(newDay === undefined);
          }}
        />{" "}
        <input
          className="mt-3"
          disabled={buttonDisabled}
          type="submit"
          value="Update appointment"
          name="submit"
          id="submit"
          onClick={(event) => {
            event.preventDefault();
            setButtonDisabled(true);
            updateAppointment(bookingid, signature, expires, appointment)
              .then(() => {
                toast("You appointment has been updated");
                setButtonDisabled(false);
              })
              .catch((err) => {
                toast(
                  "We could not update your appointment, please try again later."
                );
                setIOError(true);
                setButtonDisabled(false);
              });
          }}
        />
        <ToastContainer />
      </div>
    </>
  );
}

export default ReschedulePage;
