import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import logo from "./logo.svg";
import "./Header.css";
import UserInfo from "../../../model/UserInfo";
import { Link } from "react-router-dom";

export default function Header({ userInfo }: { userInfo: UserInfo }) {
  return (
    <div className="Header">
      <Navbar expand="lg" bg="light">
        <Navbar.Brand href="/">
          <img alt="ECL Logo" src={logo} />
        </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Link to="/admin/settings" className="nav-link">
              Settings
            </Link>
            <Link to="/admin/appointments" className="nav-link">
              Appointments
            </Link>
            <Link to="/admin/intermediaries" className="nav-link">
              Intermediaries
            </Link>
            <Link to="/admin/exports" className="nav-link">
              Exports
            </Link>
            <Link to="/admin/env" className="nav-link">
              Environment
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <hr />
    </div>
  );
}
