import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IntermediaryCompany } from "../../../model/Intermediary";

function listIntermediaries(): Promise<IntermediaryCompany[]> {
  return fetch("/api/admin/intermediaryCompanies").then(async (result) => {
    if (result.status !== 200) {
      return Promise.reject();
    }
    return await result.json();
  });
}

export default function ListIntermdiaries() {
  const [intermediaries, setIntermdiaries] = useState<
    IntermediaryCompany[] | undefined
  >(undefined);

  useEffect(() => {
    listIntermediaries().then((intermediaries: IntermediaryCompany[]) => {
      setIntermdiaries(intermediaries);
    });
  }, []);

  if (intermediaries === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <h1>List of intermediaries</h1>
      <div className="row">
        <div className="col-6">
          <strong>name</strong>
        </div>
        <div className="col-2">
          <strong>code</strong>
        </div>
      </div>
      {intermediaries
        .sort((i1: IntermediaryCompany, i2: IntermediaryCompany) => {
          var nameA = i1.displayName.toUpperCase(); // ignore upper and lowercase
          var nameB = i2.displayName.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        })
        .map((intermediary, i) => {
          return (
            <div key={i}>
              <div className="row">
                <div className="col-6">
                  <Link to={"/admin/intermediaries/" + intermediary.id}>
                    {intermediary.displayName}
                  </Link>{" "}
                </div>
                <div className="col-2">{intermediary.code}</div>
              </div>
            </div>
          );
        })}
      <div className="d-flex justify-content-end">
        <a
          href="/admin/intermediaries/new"
          className="btn btn-primary"
          style={{ color: "white" }}
        >
          ADD
        </a>
      </div>
    </>
  );
}
