import moment from "moment-timezone";
import { useState } from "react";

const DAYS = [
  "",
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
];
const MONTHS: Array<string> = [
  "",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const YEARS_SET = [
  "",
  moment().tz("Europe/Amsterdam").add(-6, "months").format("YYYY"),
  moment().tz("Europe/Amsterdam").format("YYYY"),
  moment().tz("Europe/Amsterdam").add(1, "years").format("YYYY"),
];
const YEARS = new Set(YEARS_SET);

export default function ArrivalDatePicker({
  value,
  onChange,
}: {
  value: Date | undefined;
  onChange: (date: Date | undefined) => void;
}) {
  const [arrivalDayOfMonth, setArrivalDayOfMonth] = useState<
    number | undefined
  >(value !== undefined ? moment(value).date() : undefined);
  const [arrivalMonthOfYear, setArrivalMonthOfYear] = useState<
    string | undefined
  >(value !== undefined ? moment(value).format("MMMM") : undefined);
  const [arrivalYear, setArrivalYear] = useState<number | undefined>(
    value !== undefined ? moment(value).year() : undefined
  );

  function updateArrivalDay(
    newDay: number | undefined,
    newMonth: number | undefined,
    newYear: number | undefined
  ) {
    setArrivalDayOfMonth(newDay);
    setArrivalMonthOfYear(
      newMonth !== undefined ? MONTHS[newMonth + 1] : undefined
    );
    setArrivalYear(newYear);

    if (
      newDay !== undefined &&
      newMonth !== undefined &&
      newYear !== undefined
    ) {
      const newArrivalDay = moment()
        .startOf("day")
        .year(newYear)
        .month(newMonth)
        .date(newDay);

      setArrivalDayOfMonth(newArrivalDay.date());
      setArrivalMonthOfYear(MONTHS[newArrivalDay.month() + 1]);
      setArrivalYear(newArrivalDay.year());

      onChange(newArrivalDay.toDate());
    } else {
      onChange(undefined);
    }
  }

  return (
    <div className="ArrivalDay d-flex mb-3">
      <select
        className=""
        value={arrivalDayOfMonth}
        onChange={(e) => {
          updateArrivalDay(
            e.target.value !== "" ? Number.parseInt(e.target.value) : undefined,
            arrivalMonthOfYear !== undefined
              ? MONTHS.indexOf(arrivalMonthOfYear) - 1
              : undefined,
            arrivalYear
          );
        }}
      >
        {DAYS.map((n) => {
          return (
            <option value={n} key={n}>
              {n}
            </option>
          );
        })}
      </select>
      <span className="ms-1"> - </span>
      <select
        className="ms-1"
        value={arrivalMonthOfYear}
        onChange={(e) => {
          updateArrivalDay(
            arrivalDayOfMonth,
            e.target.value !== ""
              ? MONTHS.indexOf(e.target.value) - 1
              : undefined,
            arrivalYear
          );
        }}
      >
        {MONTHS.map((n) => {
          return (
            <option value={n} key={n}>
              {n}
            </option>
          );
        })}
      </select>
      <span className="ms-1"> - </span>
      <select
        className="ms-1"
        value={arrivalYear}
        onChange={(e) => {
          updateArrivalDay(
            arrivalDayOfMonth,
            arrivalMonthOfYear !== undefined
              ? MONTHS.indexOf(arrivalMonthOfYear) - 1
              : undefined,
            e.target.value !== "" ? Number.parseInt(e.target.value) : undefined
          );
        }}
      >
        {Array.from(YEARS).map((n) => {
          return (
            <option value={n} key={n}>
              {n}
            </option>
          );
        })}
      </select>
    </div>
  );
}
