import _, { Dictionary } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import Booking from "../../../model/Booking";

function BookingEntry({ booking }: { booking: Booking }) {
  return (
    <>
      <div className="col-3">
        {booking.appointment.attendee?.gender?.toString() === "female"
          ? "ms. "
          : "mr. "}
        {booking.appointment.attendee?.firstname}{" "}
        {booking.appointment.attendee?.lastName}
      </div>
      <div className="col-2">
        {moment(booking.appointment.appointmentDate).format("HH:mm")} -{" "}
        {moment(booking.appointment.appointmentDate)
          .add(booking.appointment.durationInMinutes, "minutes")
          .format("hh:mm")}
      </div>
      <div className="col-2">{booking.appointment.arrivalDay}</div>
      <div className="col-2">
        {booking.appointment.nrOfAdults ||
          0 + (booking.appointment.nrOfChildren || 0)}
      </div>
      <div className="col-2">
        <a href={booking.rescheduleUrl}>reschedule</a>
      </div>
    </>
  );
}

function retrieveBookings(): Promise<Booking[]> {
  return fetch("/api/admin/bookings").then(async (result) => {
    if (result.status !== 200) {
      return Promise.reject();
    }
    return await result.json();
  });
}
export default function ListAppointments() {
  const [bookings, setBookings] = useState<Booking[] | undefined>(undefined);

  useEffect(() => {
    retrieveBookings().then((bookings: Booking[]) => {
      setBookings(bookings);
    });
  }, []);

  if (bookings === undefined) {
    return <div>Loading...</div>;
  }

  const group: Dictionary<Booking[]> = _.groupBy(bookings, (b: Booking) => {
    return moment(b.appointment.appointmentDate).format("YYYY-MM-DD");
  });

  return (
    <>
      <h1>List of upcomming appointments</h1>
      <div className="row">
        <div className="col-3">
          <strong>name</strong>
        </div>
        <div className="col-2">
          <strong>Time</strong>
        </div>
        <div className="col-2">
          <strong>Arrival</strong>
        </div>
        <div className="col-2">
          <strong>Nr Of persons</strong>
        </div>
        <div className="col-2">
          <strong>Action</strong>
        </div>
      </div>
      {Object.keys(group)
        .sort()
        .map((entry: string) => {
          return (
            <div key={entry}>
              <div className="row">
                <div className="col-7">
                  <h2>{entry}</h2>
                </div>
                <div className="col-1">
                  <a
                    href={"/appointmentcardpdf?date=" + entry}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      style={{ maxHeight: "30px" }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M184 208c0-4.406-3.594-8-8-8S168 203.6 168 208c0 2.062 .2969 23.31 9.141 50.25C179.1 249.6 184 226.2 184 208zM256 0v128h128L256 0zM80 422.4c0 9.656 10.47 11.97 14.38 6.375C99.27 421.9 108.8 408 120.1 388.6c-14.22 7.969-27.25 17.31-38.02 28.31C80.75 418.3 80 420.3 80 422.4zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM292 312c24.26 0 44 19.74 44 44c0 24.67-18.94 44-43.13 44c-5.994 0-11.81-.9531-17.22-2.805c-20.06-6.758-38.38-15.96-54.55-27.39c-23.88 5.109-45.46 11.52-64.31 19.1c-14.43 26.31-27.63 46.15-36.37 58.41C112.1 457.8 100.8 464 87.94 464C65.92 464 48 446.1 48 424.1c0-11.92 3.74-21.82 11.18-29.51c16.18-16.52 37.37-30.99 63.02-43.05c11.75-22.83 21.94-46.04 30.33-69.14C136.2 242.4 136 208.4 136 208c0-22.05 17.95-40 40-40c22.06 0 40 17.95 40 40c0 24.1-7.227 55.75-8.938 62.63c-1.006 3.273-2.035 6.516-3.082 9.723c7.83 14.46 17.7 27.21 29.44 38.05C263.1 313.4 284.3 312.1 287.6 312H292zM156.5 354.6c17.98-6.5 36.13-11.44 52.92-15.19c-12.42-12.06-22.17-25.12-29.8-38.16C172.3 320.6 164.4 338.5 156.5 354.6zM292.9 368C299 368 304 363 304 356.9C304 349.4 298.6 344 292 344H288c-.3438 .0313-16.83 .9687-40.95 4.75c11.27 7 24.12 13.19 38.84 18.12C288 367.6 290.5 368 292.9 368z" />{" "}
                    </svg>
                  </a>
                </div>
              </div>
              {_.orderBy(group[entry], (b: Booking) => {
                return b.appointment.appointmentDate;
              }).map((b: Booking, i: number) => {
                return (
                  <div className="row" key={entry + "_" + i}>
                    <BookingEntry booking={b} />
                  </div>
                );
              })}
            </div>
          );
        })}
    </>
  );
}
