import { useState } from "react";
import AppointmentDetails from "../components/AppointmentDetails";
import AttendeeCategory from "../components/AttendeeCategory";
import AttendeeInformation from "../components/AttendeeInformation";
import BSNStatus from "../components/BSNStatus";
import IntermediateryInformation from "../components/IntermediateryInformation";
import LengthOfStay from "../components/LengthOfStay";
import Notice from "../components/Notice";
import Payment from "../components/Payment";
import WhoForSelector from "../components/WhoForSelector";
import Appointment, {
  AppointmentForEnum,
  AttendeeCategoryEnum,
  BSNStatusEnum,
  LengthOfStayEnum,
} from "../model/Appointment";
import Attendee from "../model/Attendee";
import Intermediary from "../model/Intermediary";
import "./FormPage.css";

function FormPage() {
  const [appointment, setAppointment] = useState<Appointment>({});

  const whoForRequirementsMet: boolean =
    appointment.appointmentFor === AppointmentForEnum.MYSELF ||
    (appointment.appointmentFor === AppointmentForEnum.OTHER &&
      appointment.intermediary?.isValid === true);

  const bsnRequirementsMet: boolean =
    whoForRequirementsMet &&
    (appointment.BSNStatus === BSNStatusEnum.BSNNO ||
      appointment.BSNStatus === BSNStatusEnum.BSNNO_DIPLOMATICYES_LEIDENYES);

  const attendeeCategoryRequirementsMet: boolean =
    bsnRequirementsMet &&
    appointment.attendeeCategory !== undefined &&
    appointment.attendeeCategory !== AttendeeCategoryEnum.NONE_ABOVE;

  const lengthOfStayMet: boolean =
    bsnRequirementsMet &&
    attendeeCategoryRequirementsMet &&
    appointment.lengthOfStay === LengthOfStayEnum.LONGER_THEN_4MONTH;

  const updateIntermediate = (intermediary: Intermediary) => {
    if (appointment.attendee === undefined) {
      appointment.attendee = {};
    }
    if (intermediary.companyName !== "Other") {
      appointment.attendee.companyName = intermediary.companyName;
    } else {
      appointment.attendee.companyName = "";
    }

    setAppointment(
      Object.assign({}, appointment, {
        intermediary: intermediary,
        attendee: appointment.attendee,
      })
    );
  };
  return (
    <>
      <div className="container-fluid FormPage">
        <div className="row">
          <div className="col">
            <h1>Appointment form</h1>
            <div className="line" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <WhoForSelector
              appointment={appointment}
              onChange={(appointment: Appointment) => {
                setAppointment(Object.assign({}, appointment));
              }}
            />
            {appointment.appointmentFor === AppointmentForEnum.OTHER ? (
              <IntermediateryInformation
                intermediary={appointment.intermediary}
                onChange={updateIntermediate}
              />
            ) : undefined}
            {whoForRequirementsMet ? (
              <BSNStatus
                bsnStatus={appointment.BSNStatus}
                onChange={(status: BSNStatusEnum) => {
                  setAppointment(
                    Object.assign({}, appointment, {
                      BSNStatus: status,
                    })
                  );
                }}
              />
            ) : undefined}
            {appointment.BSNStatus === BSNStatusEnum.BSNYES_DIPLOMATICNO ? (
              <Notice>
                The Leiden International Centre is not authorized to carry out
                resettlement registrations (hervestiging). You can register at
                your{" "}
                <a href="https://www.leideninternationalcentre.nl/our-story">
                  municipality.
                </a>
                If you do not yet have an address, you can register at the
                municipality where your company is located.
              </Notice>
            ) : undefined}
            {appointment.BSNStatus ===
            BSNStatusEnum.BSNNO_DIPLOMATICYES_LEIDENNO ? (
              <Notice>
                The Leiden International Centre is not authorized to carry out
                diplomatic registrations for municipalities other than Leiden.
                You can register at your municipality.
              </Notice>
            ) : undefined}
            {bsnRequirementsMet ? (
              <AttendeeCategory
                attendeeCategory={appointment.attendeeCategory}
                onChange={(
                  attendeeCategory: AttendeeCategoryEnum | undefined
                ) => {
                  setAppointment(
                    Object.assign({}, appointment, {
                      attendeeCategory: attendeeCategory,
                    })
                  );
                }}
              />
            ) : undefined}
            {appointment.attendeeCategory ===
            AttendeeCategoryEnum.NONE_ABOVE ? (
              <Notice>
                The Leiden International Centre cannot register you. You can
                register at your municipality. If you do not yet have an
                address, you can register at the{" "}
                <a href="https://www.leideninternationalcentre.nl/our-story">
                  municipality
                </a>{" "}
                where the company is located.
              </Notice>
            ) : undefined}
            {attendeeCategoryRequirementsMet ? (
              <LengthOfStay
                lengthOfStay={appointment.lengthOfStay}
                onChange={(lengthOfStay: LengthOfStayEnum) => {
                  setAppointment(
                    Object.assign({}, appointment, {
                      lengthOfStay: lengthOfStay,
                    })
                  );
                }}
              />
            ) : undefined}
            {appointment.attendeeCategory !== AttendeeCategoryEnum.NONE_ABOVE &&
            appointment.lengthOfStay !== undefined &&
            appointment.lengthOfStay === LengthOfStayEnum.LESS_THEN_4MONTH ? (
              <Notice>
                You are not required to register if you stay less than 4 months.
                If you do require a BSN, please call{" "}
                <a href="tel:0031715165165">+31 71 516 516 5</a> from abroad or{" "}
                <a href="tel:0715165165">071 516 516 5</a> from within the
                Netherlands for registration in the RNI. The staff at the
                Customer Contact Centre speak both Dutch and English.
              </Notice>
            ) : undefined}
            {lengthOfStayMet ? (
              <AttendeeInformation
                attendee={appointment.attendee}
                onChange={(attendee: Attendee) => {
                  setAppointment(
                    Object.assign({}, appointment, {
                      attendee: attendee,
                    })
                  );
                }}
              />
            ) : undefined}
            {lengthOfStayMet && appointment.attendee?.isValid === true ? (
              <>
                <AppointmentDetails
                  appointment={appointment}
                  onChange={(updatedAppointment) => {
                    setAppointment(Object.assign({}, updatedAppointment));
                  }}
                />
              </>
            ) : undefined}
            <Payment
              enabled={
                lengthOfStayMet &&
                appointment.allEUCitizens !== undefined &&
                appointment.attendee?.isValid === true &&
                appointment.agreeToTerms === true
              }
              appointment={appointment}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default FormPage;
