import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

interface ConfigurationSettings {
  confirmationMail: string;
  introHtml: string;
  confirmationHtml: string;
}

const getSettings = async (): Promise<ConfigurationSettings> => {
  return fetch("/api/admin/configuration").then((result) => {
    return result.json();
  });
};

const updateSettings = async (
  configurationSettings: ConfigurationSettings
): Promise<ConfigurationSettings> => {
  return fetch("/api/admin/configuration", {
    method: "PUT",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(configurationSettings),
  }).then((result) => {
    return result.json();
  });
};

export default function Settings() {
  const [currentSettings, setCurrentSettings] = useState<
    ConfigurationSettings | undefined
  >(undefined);

  const [confirmationMailState, setConfirmationMailState] = useState(() =>
    EditorState.createEmpty()
  );
  const [confirmationHtmlState, setConfirmationHtmlState] = useState(() =>
    EditorState.createEmpty()
  );
  const [introState, setIntroState] = useState(() => EditorState.createEmpty());

  useEffect(() => {
    getSettings().then((settings: ConfigurationSettings) => {
      setCurrentSettings(settings);
    });
  }, []);

  useEffect(() => {
    if (currentSettings === undefined) return;

    const confirmationMailFromHTML = convertFromHTML(
      currentSettings.confirmationMail || ""
    );
    const confirmationHtmlFromHTML = convertFromHTML(
      currentSettings.confirmationHtml || ""
    );
    const introFromHTML = convertFromHTML(currentSettings.introHtml || "");

    setConfirmationMailState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          confirmationMailFromHTML.contentBlocks,
          confirmationMailFromHTML.entityMap
        )
      )
    );
    setConfirmationHtmlState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          confirmationHtmlFromHTML.contentBlocks,
          confirmationHtmlFromHTML.entityMap
        )
      )
    );
    setIntroState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          introFromHTML.contentBlocks,
          introFromHTML.entityMap
        )
      )
    );
  }, [currentSettings]);

  return (
    <form className="Settings">
      <div className="mb-3">
        <label>Confirmation mail</label>
        <div>
          <Editor
            wrapperClassName="settings-wrapper-class"
            editorClassName="settings-editor-class"
            toolbarClassName="settings-toolbar-class"
            wrapperStyle={{
              height: "450px",
              display: "block",
            }}
            editorState={confirmationMailState}
            onEditorStateChange={setConfirmationMailState}
          />
        </div>
      </div>
      <div className="mb-3">
        <label>Confirmation text</label>
        <div>
          <Editor
            wrapperClassName="settings-wrapper-class"
            editorClassName="settings-editor-class"
            toolbarClassName="settings-toolbar-class"
            wrapperStyle={{
              height: "450px",
              display: "block",
            }}
            editorState={confirmationHtmlState}
            onEditorStateChange={setConfirmationHtmlState}
          />
        </div>
      </div>
      <div className="mb-3">
        <label>Intro text</label>
        <div>
          <Editor
            wrapperClassName="settings-wrapper-class"
            editorClassName="settings-editor-class"
            toolbarClassName="settings-toolbar-class"
            wrapperStyle={{
              height: "450px",
              display: "block",
            }}
            editorState={introState}
            onEditorStateChange={setIntroState}
          />
        </div>
      </div>
      <button
        className="btn btn-primary"
        onClick={(event) => {
          event.preventDefault();
          updateSettings({
            confirmationHtml: draftToHtml(
              convertToRaw(confirmationHtmlState.getCurrentContent())
            ),
            confirmationMail: draftToHtml(
              convertToRaw(confirmationMailState.getCurrentContent())
            ),
            introHtml: draftToHtml(
              convertToRaw(introState.getCurrentContent())
            ),
          }).then((newSettings) => {
            setCurrentSettings(newSettings);
          });
        }}
      >
        SAVE
      </button>
    </form>
  );
}
