import UserInfo from "../model/UserInfo";

/*
    Auth related API Calls
*/
export async function login(username: string, password: string) {
  const response = await fetch("/api/auth/signin", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({ usernameOrEmail: username, password: password }),
  });

  return await defaultJsonResponseHandler(response);
}

export async function me(): Promise<UserInfo | undefined> {
  return fetch("/api/auth/me", {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    }
    return Promise.resolve(undefined);
  });
}

/*
    Misc api methods
*/
export const ErrorCodes = {
  ACCESS_DENIED: "ACCESS_DENIED",
  NOT_FOUND: "NOT_FOUND",
};

async function defaultJsonResponseHandler(response: Response) {
  if (response.ok) {
    return await response.json();
  } else {
    if (response.status === 401 || response.status === 403) {
      return Promise.reject(ErrorCodes.ACCESS_DENIED);
    }
    if (response.status === 404) {
      return Promise.reject(ErrorCodes.NOT_FOUND);
    }
    return Promise.reject(JSON.stringify(response));
  }
}
