import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { login, me } from "../../../services/APIService";
import UserInfo from "../../../model/UserInfo";
import logo from "../header/logo.svg";

function Login() {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState<UserInfo | undefined | null>(
    undefined
  );

  useEffect(() => {
    me().then((info: UserInfo | undefined) => {
      if (info === undefined) {
        setUserInfo(null);
      } else {
        setUserInfo(info);
      }
    });
  }, []);
  if (userInfo === undefined) {
    return <div>Loading....</div>;
  }
  if (userInfo !== null) {
    return <Navigate to="/admin/home" />;
  }

  function loginUser(email: string, password: string) {
    login(email, password)
      .then((auth) => {
        setLoggedIn(true);
        setErrorMessage("");
        navigate("/admin/home");
      })
      .catch((error) => {
        if (error === "Access denied") {
          localStorage.removeItem("access_token");
          setErrorMessage("Password or username incorrect");
        } else {
          alert("Onbekende fout" + error);
        }
      });
  }

  return !loggedIn ? (
    <div className="container d-flex flex-column justify-content-center">
      <img className="mt-5 w-25" src={logo} alt="Leiden International Centre" />
      <h3>Login</h3>
      {errorMessage !== "" ? (
        <p className="text-warning">{errorMessage}</p>
      ) : undefined}
      <form className="w-25">
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="email"
            autoComplete="username"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            className="form-control"
            placeholder="password"
            autoComplete="current-password"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
        </div>
        <div className="form-group mt-3 d-flex justify-content-end">
          <input
            type="submit"
            onClick={(event) => {
              event.preventDefault();
              loginUser(email, password);
            }}
            className="btnSubmit"
            value="Login"
          />
        </div>
      </form>
    </div>
  ) : (
    <div>You are logged in</div>
  );
}

export default Login;
