import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./components/admin/login/Login";
import AdminPage from "./pages/AdminPage";
import ConfirmationPage from "./pages/ConfirmationPage";
import FormPage from "./pages/FormPage";
import "iframe-resizer/js/iframeResizer.contentWindow";
import UserInfo from "./model/UserInfo";
import { me } from "./services/APIService";
import ReschedulePage from "./pages/ReschedulePage";

function App() {
  function RedirectIfNeeded() {
    const [userInfo, setUserInfo] = useState<UserInfo | undefined | null>(
      undefined
    );

    useEffect(() => {
      me().then((info: UserInfo | undefined) => {
        if (info === undefined) {
          setUserInfo(null);
        } else {
          setUserInfo(info);
        }
      });
    });
    if (userInfo === undefined) {
      return <div>Loading....</div>;
    }

    return userInfo !== null ? (
      <Navigate to="/admin/home" />
    ) : (
      <Navigate to="/admin/login" />
    );
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<FormPage />} />
        <Route
          path="/confirmation/:bookingid"
          element={<ConfirmationPage />}
        ></Route>
        <Route path="/admin/login" element={<Login />} />

        <Route path="/admin" element={<RedirectIfNeeded />} />
        <Route path="/admin/*" element={<AdminPage />} />
        <Route path="/reschedule/:bookingid" element={<ReschedulePage />} />
      </Routes>
    </div>
  );
}

export default App;
