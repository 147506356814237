import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
type ConfirmationPageParams = {
  bookingid: string;
};
type PaymentStatusResponse = {
  status: string;
  errorMessage: string;
};

const getPaymentStatus = async (
  bookingid: string
): Promise<PaymentStatusResponse> => {
  const response = await fetch("/api/form/paymentStatus", {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      bookingId: bookingid,
    }),
  });
  if (response.status !== 200) {
    return Promise.resolve({
      status: "error",
      errorMessage: "Booking not found",
    });
  }
  return response.json();
};

const StatusMessage = ({
  paymentStatus,
  confirmationHtml,
}: {
  paymentStatus: PaymentStatusResponse;
  confirmationHtml: string;
}) => {
  switch (paymentStatus.status) {
    case undefined:
      return <div>We are retrieving the status of your payment...</div>;
    case "ready_for_processing":
      return confirmationHtml !== undefined ? (
        <div dangerouslySetInnerHTML={{ __html: confirmationHtml }} />
      ) : (
        <div>Loading...</div>
      );
    case "done":
      return confirmationHtml !== undefined ? (
        <div dangerouslySetInnerHTML={{ __html: confirmationHtml }} />
      ) : (
        <div>Loading...</div>
      );
    case "failed":
      return (
        <div>
          We were unable to process your payment, please try again later.
        </div>
      );
    case "error":
      return (
        <div>
          There was an error processing your order, we got the following reason{" "}
          <pre>{paymentStatus.errorMessage}</pre>
        </div>
      );
    default:
      return (
        <div>We where unable to process your payment, please try again.</div>
      );
  }
};

export default function ConfirmationPage() {
  let { bookingid } = useParams<ConfirmationPageParams>();

  const [confirmationHtml, setConfirmationHtml] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    fetch("/api/form/confirmationHtml").then((response) => {
      response.text().then((msg: string) => {
        setConfirmationHtml(msg);
      });
    });
  });

  const [paymentStatus, setPaymentStatus] = useState<
    PaymentStatusResponse | undefined
  >(undefined);

  useEffect(() => {
    if (bookingid === undefined) return;

    getPaymentStatus(bookingid)
      .then((newPaymentStatus: PaymentStatusResponse) => {
        setPaymentStatus(newPaymentStatus);
      })
      .catch((err) => {
        setPaymentStatus({ status: "error", errorMessage: "" + err });
      });
  }, [bookingid]);

  if (confirmationHtml === undefined || paymentStatus === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      <StatusMessage
        confirmationHtml={confirmationHtml}
        paymentStatus={paymentStatus}
      />
    </div>
  );
}
