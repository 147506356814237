export default interface Attendee {
  firstname?: string;
  lastName?: string;
  gender?: GenderEnum;
  companyName?: string;
  nationality?: string;
  email?: string;
  phone?: string;
  isValid?: boolean;
}

export enum GenderEnum {
  male,
  female,
  unkown,
}
