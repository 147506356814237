import { useState } from "react";
import Appointment from "../model/Appointment";
declare global {
  interface Window {
    parentIFrame: ParentIFrame;
  }
}

interface ParentIFrame {
  close(): void;
}

export default function Payment({
  appointment,
  enabled,
}: {
  appointment: Appointment;
  enabled: boolean;
}) {
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

  const getPaymentUrl = async (
    appointment: Appointment
  ): Promise<string | undefined> => {
    const response = await fetch("/api/form/createAppointment", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(appointment),
    });

    if (response.status !== 200) {
      return Promise.reject(await response.text());
    }
    return response.text();
  };
  return (
    <div className="payment">
      <input
        className="mt-3"
        disabled={!enabled || buttonDisabled}
        type="submit"
        value="Make appointment"
        name="submit"
        id="submit"
        onClick={(event) => {
          event.preventDefault();
          setButtonDisabled(true);
          getPaymentUrl(appointment)
            .then((redirectUrl) => {
              if (redirectUrl !== undefined) {
                window.open(redirectUrl, "_blank")?.focus();
                if ("parentIFrame" in window) {
                  window.parentIFrame.close();
                }
              }
            })
            .catch((err) => {
              setButtonDisabled(false);
              let obj = JSON.parse(err);
              console.error(err);
              alert(obj.message);
            });
        }}
      />
    </div>
  );
}
