import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Intermediary, { IntermediaryCompany } from "../model/Intermediary";
import Notice from "./Notice";

const getIntermediaryOptions = async (): Promise<IntermediaryCompany[]> => {
  const response = await fetch("/api/form/intermediaries", {
    method: "GET",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const intermediaries: IntermediaryCompany[] = await response.json();

  intermediaries.unshift({
    id: "",
    code: "",
    displayName: "Other",
  });
  intermediaries.unshift({
    id: "",
    code: "",
    displayName: "",
  });
  return Promise.resolve(intermediaries);
};

const isValidIntermediary = async (
  intermediaryCompany: IntermediaryCompany
): Promise<boolean> => {
  const response = await fetch("/api/form/isValidIntermediary", {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(intermediaryCompany),
  });

  return (await response.text()) === "true";
};

export default function IntermediateryInformation({
  intermediary,
  onChange,
}: {
  onChange: (intermediary: Intermediary) => void;
  intermediary: Intermediary | undefined;
}) {
  const [intermediaryOptions, setIntermediaryOptions] = useState<
    IntermediaryCompany[]
  >([]);
  const { register, watch, formState } = useForm<Intermediary>({
    mode: "all",
    defaultValues: intermediary,
  });

  const { isValid } = formState;
  const {
    companyName,
    companyCode,
    otherCompanyName,
    firstname,
    lastName,
    email,
    phone,
  } = watch();

  useEffect(() => {
    onChange({ ...intermediary, isValid: isValid });
  }, [isValid]);

  useEffect(() => {
    getIntermediaryOptions().then((options: IntermediaryCompany[]) => {
      setIntermediaryOptions(options);
    });
  }, []);

  return (
    <>
      <form>
        <h2>Details intermediary or contact person</h2>
        <div className="form-group has-validation">
          <label htmlFor="companyName" className="form-label">
            Company name
          </label>
          <select
            {...register("companyName", {
              required: true,
              value: companyName,
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                onChange({
                  ...intermediary,
                  companyName: e.target.value,
                });
              },
            })}
            className={`form-select ${
              formState.touchedFields.companyName !== undefined || companyName
                ? formState.errors.companyName !== undefined
                  ? "is-invalid"
                  : "is-valid"
                : ""
            }`}
          >
            {intermediaryOptions.map(
              (option: IntermediaryCompany, i: number) => {
                return (
                  <option key={i} value={option.displayName}>
                    {option.displayName}
                  </option>
                );
              }
            )}
          </select>
          <div className="invalid-feedback">
            Please provide a valid company name.
          </div>
        </div>
        {companyName === "Other" ? (
          <>
            <div className="form-group has-validation">
              <label htmlFor="otherCompanyName" className="form-label is-valid">
                Other company name
              </label>
              <input
                {...register("otherCompanyName", {
                  required: true,
                  onChange: (e: ChangeEvent<HTMLInputElement>) => {
                    onChange({
                      ...intermediary,
                      otherCompanyName: e.target.value,
                    });
                  },
                })}
                className={`form-control ${
                  formState.touchedFields.otherCompanyName || otherCompanyName
                    ? formState.errors.otherCompanyName
                      ? "is-invalid"
                      : "is-valid"
                    : ""
                }`}
                placeholder="Please provide name of other company"
              />
              <div className="invalid-feedback">
                Please provide a valid company name.
              </div>
            </div>
          </>
        ) : undefined}
        {companyName !== "Other" ? (
          <div className="form-group has-validation">
            <label htmlFor="companyCode" className={`form-label`}>
              Company code
            </label>
            <input
              className={`form-control ${
                formState.touchedFields.companyCode || companyCode
                  ? formState.errors.companyCode !== undefined
                    ? "is-invalid"
                    : "is-valid"
                  : ""
              }`}
              {...register("companyCode", {
                validate: async (value: string | undefined) => {
                  return await isValidIntermediary({
                    id: companyName || "",
                    displayName: companyName || "",
                    code: value || "",
                  });
                },
                onChange: (e: ChangeEvent<HTMLInputElement>) => {
                  onChange({
                    ...intermediary,
                    companyCode: e.target.value,
                  });
                },
              })}
            />
            <div id="validationCompanyCode" className="invalid-feedback">
              Please provide a valid company code
            </div>
            <div id="validationCompanyCode" className="valid-feedback">
              This is a valid code
            </div>
          </div>
        ) : null}
        <Notice>
          It is only possible to charge to an account if a Service-level
          agreement (SLA) is in place between Leiden International Centre and
          your organisation. If this is not the case, all charges are to be paid
          immediately. If one wishes to charge to an account, an SLA must be
          reached before proceeding. To do so, please contact
          <a className="ms-1" href="mailto:info@leideninternationalcentre.nl">
            info@leideninternationalcentre.nl
          </a>
          .
        </Notice>
        <div className="form-group has-validation">
          <label htmlFor="firstname" className="form-label is-valid">
            First name
          </label>
          <input
            {...register("firstname", {
              required: true,
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                onChange({
                  ...intermediary,
                  firstname: e.target.value,
                });
              },
            })}
            className={`form-control ${
              formState.touchedFields.firstname || firstname
                ? formState.errors.firstname
                  ? "is-invalid"
                  : "is-valid"
                : ""
            }`}
            type="text"
            placeholder="First name"
          />
          <div className="invalid-feedback">Please provide a first name</div>
        </div>
        <div className="form-group has-validation">
          <label htmlFor="lastName" className="form-label">
            Last name
          </label>
          <input
            {...register("lastName", {
              required: true,
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                onChange({
                  ...intermediary,
                  lastName: e.target.value,
                });
              },
            })}
            className={`form-control ${
              formState.touchedFields.lastName || lastName
                ? formState.errors.lastName
                  ? "is-invalid"
                  : "is-valid"
                : ""
            }`}
            type="text"
            placeholder="Last name"
          />
          <div className="invalid-feedback">Please provide a last name</div>
        </div>
        <div className="form-group has-validation">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            {...register("email", {
              required: true,
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                onChange({
                  ...intermediary,
                  email: e.target.value,
                });
              },
            })}
            className={`form-control ${
              formState.touchedFields.email || email
                ? formState.errors.email
                  ? "is-invalid"
                  : "is-valid"
                : ""
            }`}
          />
          <div className="invalid-feedback">
            Please provide an email address
          </div>
        </div>
        <div className="form-group has-validation">
          <label htmlFor="phone" className="form-label">
            Phone number
          </label>
          <input
            {...register("phone", {
              required: true,
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                onChange({
                  ...intermediary,
                  phone: e.target.value,
                });
              },
            })}
            className={`form-control ${
              formState.touchedFields.phone || phone
                ? formState.errors.phone
                  ? "is-invalid"
                  : "is-valid"
                : ""
            }`}
            type="phone"
            placeholder="Phone number"
          />
          <div className="invalid-feedback">Please provide a phone number</div>
        </div>
      </form>
    </>
  );
}
