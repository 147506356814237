import { BSNStatusEnum } from "../model/Appointment";
import SubTitle from "./SubTitle";

export default function BSNStatus({
  bsnStatus,
  onChange,
}: {
  onChange: (bsnStatus: BSNStatusEnum) => void;
  bsnStatus: BSNStatusEnum | undefined;
}) {
  return (
    <>
      <SubTitle>Information of primary attendee</SubTitle>
      <p>
        Does primary attendee have a Citizen Service Number{" "}
        <i>(Burgerservicenummer– BSN)</i>?
      </p>
      <div className="form-check">
        <input
          value={BSNStatusEnum.BSNYES_DIPLOMATICNO}
          className="form-check-input"
          type="radio"
          name="BSNYES_DIPLOMATICNO"
          id="BSNYES_DIPLOMATICNO"
          checked={bsnStatus === BSNStatusEnum.BSNYES_DIPLOMATICNO}
          onChange={() => {
            onChange(BSNStatusEnum.BSNYES_DIPLOMATICNO);
          }}
        />
        <label
          className="radiolabel form-check-label"
          htmlFor="BSNYES_DIPLOMATICNO"
        >
          Yes, and I do <strong>not</strong> have{" "}
          <a href="https://www.leideninternationalcentre.nl/before-moving/visa-residence/privileged-persons">
            diplomatic status
          </a>
        </label>
      </div>
      <div className="form-check">
        <input
          value={BSNStatusEnum.BSNNO}
          className="form-check-input"
          type="radio"
          name="BSNNO"
          id="BSNNO"
          checked={bsnStatus === BSNStatusEnum.BSNNO}
          onChange={() => {
            onChange(BSNStatusEnum.BSNNO);
          }}
        />
        <label className="radiolabel form-check-label" htmlFor="BSNNO">
          No
        </label>
      </div>
      <div className="form-check">
        <input
          value={BSNStatusEnum.BSNNO_DIPLOMATICYES_LEIDENYES}
          className="form-check-input"
          type="radio"
          name="BSNNO_DIPLOMATICYES_LEIDENYES"
          id="BSNNO_DIPLOMATICYES_LEIDENYES"
          checked={bsnStatus === BSNStatusEnum.BSNNO_DIPLOMATICYES_LEIDENYES}
          onChange={() => {
            onChange(BSNStatusEnum.BSNNO_DIPLOMATICYES_LEIDENYES);
          }}
        />
        <label
          className="radiolabel form-check-label"
          htmlFor="BSNNO_DIPLOMATICYES_LEIDENYES"
        >
          Yes; I <strong>do </strong>have{" "}
          <a href="https://www.leideninternationalcentre.nl/before-moving/visa-residence/privileged-persons">
            diplomatic status
          </a>{" "}
          <strong>and</strong> I have an address in Leiden
        </label>
      </div>
      <div className="form-check">
        <input
          value={BSNStatusEnum.BSNNO_DIPLOMATICYES_LEIDENNO}
          className="form-check-input"
          type="radio"
          name="BSNNO_DIPLOMATICYES_LEIDENNO"
          id="BSNNO_DIPLOMATICYES_LEIDENNO"
          checked={bsnStatus === BSNStatusEnum.BSNNO_DIPLOMATICYES_LEIDENNO}
          onChange={() => {
            onChange(BSNStatusEnum.BSNNO_DIPLOMATICYES_LEIDENNO);
          }}
        />
        <label
          className="radiolabel form-check-label"
          htmlFor="BSNNO_DIPLOMATICYES_LEIDENNO"
        >
          Yes; I <strong>do </strong>have{" "}
          <a href="https://www.leideninternationalcentre.nl/before-moving/visa-residence/privileged-persons">
            diplomatic status
          </a>{" "}
          <strong>but I do not</strong> have an address in Leiden
        </label>
      </div>
    </>
  );
}
