import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IntermediaryCompany } from "../../../model/Intermediary";

function getIntermediary(id: string): Promise<IntermediaryCompany> {
  return fetch("/api/admin/intermediaryCompanies/" + id).then(
    async (result) => {
      if (result.status !== 200) {
        return Promise.reject();
      }
      return await result.json();
    }
  );
}

function saveIntermediary(
  intermediary: IntermediaryCompany
): Promise<IntermediaryCompany> {
  const url =
    intermediary.id === undefined || intermediary.id === ""
      ? "/api/admin/intermediaryCompanies"
      : "/api/admin/intermediaryCompanies/" + intermediary.id;
  const method =
    intermediary.id === undefined || intermediary.id === "" ? "POST" : "PUT";

  const options: any = {
    method: method,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(intermediary),
  };

  return fetch(url, options).then(async (result) => {
    if (result.status !== 200) {
      return Promise.reject();
    }
    return await result.json();
  });
}

function deleteIntermediary(intermediary: IntermediaryCompany): Promise<void> {
  const url = "/api/admin/intermediaryCompanies/" + intermediary.id;
  const method = "DELETE";

  const options: any = {
    method: method,
  };

  return fetch(url, options).then(async (result) => {
    if (result.status !== 200) {
      return Promise.reject();
    }
    return Promise.resolve();
  });
}

export default function EditIntermediary() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [intermediary, setIntermediary] = useState<
    IntermediaryCompany | undefined
  >(undefined);

  useEffect(() => {
    if (id !== undefined && id !== "new") {
      getIntermediary(id).then((int: IntermediaryCompany) => {
        setIntermediary(int);
      });
    } else {
      setIntermediary({
        id: "",
        displayName: "",
        code: "",
      });
    }
  }, [id]);

  if (intermediary === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <h1>Edit / Add Intermediary</h1>
      <div className="mb-3">
        <label htmlFor="displayName" className="form-label">
          Display name
        </label>
        <input
          type="text"
          className="form-control"
          id="displayName"
          onChange={(event: any) => {
            setIntermediary({
              ...intermediary,
              displayName: event.target.value,
            });
          }}
          value={intermediary.displayName || ""}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="code" className="form-label">
          Code
        </label>
        <input
          type="text"
          className="form-control"
          id="code"
          onChange={(event: any) => {
            setIntermediary({ ...intermediary, code: event.target.value });
          }}
          value={intermediary.code || ""}
        />
      </div>
      <div className="d-flex justify-content-end">
        {intermediary.id !== "" ? (
          <button
            className="btn btn-danger me-1"
            onClick={() => {
              if (
                window.confirm(
                  "Are you sure you want to delete this intermediary?"
                )
              ) {
                deleteIntermediary(intermediary).then(() => {
                  navigate("/admin/intermediaries");
                });
              }
            }}
          >
            DELETE
          </button>
        ) : null}
        <button
          className="btn btn-primary"
          onClick={() => {
            saveIntermediary(intermediary).then(() => {
              navigate("/admin/intermediaries");
            });
          }}
        >
          SAVE
        </button>
      </div>
    </>
  );
}
