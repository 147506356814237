import { AttendeeCategoryEnum } from "../model/Appointment";

export default function AttendeeCategory({
  attendeeCategory,
  onChange,
}: {
  onChange: (attendeeCategory: AttendeeCategoryEnum | undefined) => void;
  attendeeCategory: AttendeeCategoryEnum | undefined;
}) {
  return (
    <>
      <label htmlFor="attendeeCategory" className="form-label">
        Category of primary attendee
      </label>

      <select
        id="attendeeCategory"
        className="form-select"
        value={attendeeCategory}
        aria-label="Category of primary attendee"
        onChange={(e) => {
          if (isNaN(parseInt(e.target.value))) {
            onChange(undefined);
            return;
          }

          onChange(parseInt(e.target.value));
        }}
      >
        <option value="">Select a category</option>
        <option value={AttendeeCategoryEnum.HIGHLY_SKILLED}>
          Highly skilled migrant (EU and non-EU)
        </option>
        <option value={AttendeeCategoryEnum.INTRA_CORPORATE_TRANSFEE}>
          Intra-corporate transferee (ICT)
        </option>
        <option value={AttendeeCategoryEnum.SCIENTIFIC_RESEARCHER}>
          Scientific researcher
        </option>
        <option value={AttendeeCategoryEnum.ENTREPENEUR}>Entrepreneur</option>
        <option value={AttendeeCategoryEnum.PRIVILEGED_PERSON}>
          Privileged person
        </option>
        <option value={AttendeeCategoryEnum.GREADUATE}>
          Recent graduate (orientation year highly educated persons)
        </option>
        <option value={AttendeeCategoryEnum.SPOUSE}>
          Spouse / partner of one of the categories mentioned
        </option>
        <option value={AttendeeCategoryEnum.CHILD}>
          Child of one of the categories mentioned
        </option>
        <option value={AttendeeCategoryEnum.NONE_ABOVE}>
          None of the above
        </option>
      </select>
    </>
  );
}
