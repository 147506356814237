import Appointment, { AppointmentForEnum } from "../model/Appointment";
export default function WhoForSelector({
  appointment,
  onChange,
}: {
  onChange: (appointment: Appointment) => void;
  appointment: Appointment;
}) {
  return (
    <div>
      <h2>Who is the appointment for?</h2>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="AppointmentForEnum.MYSELF"
          checked={appointment.appointmentFor === AppointmentForEnum.MYSELF}
          onChange={() => {
            onChange({
              ...appointment,
              appointmentFor: AppointmentForEnum.MYSELF,
            });
          }}
        />
        <label
          className="form-check-label radiolabel"
          htmlFor="AppointmentForEnum.MYSELF"
        >
          For myself (and relatives if applicable)
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="AppointmentForEnum.OTHER"
          checked={appointment.appointmentFor === AppointmentForEnum.OTHER}
          onChange={() => {
            onChange({
              ...appointment,
              appointmentFor: AppointmentForEnum.OTHER,
            });
          }}
        />
        <label
          className="radiolabel form-check-label"
          htmlFor="AppointmentForEnum.OTHER"
        >
          I am an intermediary / contact person: the appointment is for someone
          else
        </label>
      </div>
    </div>
  );
}
