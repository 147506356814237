import Attendee from "./Attendee";
import Intermediary from "./Intermediary";

interface Appointment {
  appointmentFor?: AppointmentForEnum;
  BSNStatus?: BSNStatusEnum;
  lengthOfStay?: LengthOfStayEnum;
  attendeeCategory?: AttendeeCategoryEnum;
  placeOfResidence?: string;
  companyPlace?: string;
  nrOfAdults?: number;
  nrOfChildren?: number;
  arrivalDay?: Date;
  appointmentDate?: Date;
  durationInMinutes?: number;
  signMeUpForExpatNewsletter?: boolean;
  signMeUpForCorporateNewsletter?: boolean;
  iWantToReceiveInvitationsForExpatCentreLeidenEvents?: boolean;
  allEUCitizens?: boolean;
  agreeToTerms?: boolean;
  attendee?: Attendee;
  intermediary?: Intermediary;
}

export enum AppointmentForEnum {
  MYSELF,
  OTHER,
}

export enum BSNStatusEnum {
  BSNYES_DIPLOMATICNO,
  BSNNO,
  BSNNO_DIPLOMATICYES_LEIDENYES,
  BSNNO_DIPLOMATICYES_LEIDENNO,
}

export enum LengthOfStayEnum {
  LESS_THEN_4MONTH,
  LONGER_THEN_4MONTH,
}

export enum AttendeeCategoryEnum {
  HIGHLY_SKILLED,
  INTRA_CORPORATE_TRANSFEE,
  SCIENTIFIC_RESEARCHER,
  ENTREPENEUR,
  GREADUATE,
  PRIVILEGED_PERSON,
  SPOUSE,
  CHILD,
  NONE_ABOVE,
}

export default Appointment;
